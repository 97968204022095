import { useState, useEffect } from "react";
import "./pickWinner.scss";
import axios from "axios";
import xlBk from "../../img/xlbk.jpg";
import * as XLSX from "xlsx";

const WinnerPicker = () => {
  const [contestants, setContestants] = useState("");
  const [winnerCount, setWinnerCount] = useState(1);
  const [winners, setWinners] = useState([]);
  const [isPicking, setIsPicking] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [displayNames, setDisplayNames] = useState([]);
  const [winnerGifts, setWinnerGifts] = useState([]);
  const [selectedWinner, setSelectedWinner] = useState(null);
  const [storedWinners, setStoredWinners] = useState(
    JSON.parse(localStorage.getItem("winners")) || []
  );

  const handleSelect = (winner) => {
    setSelectedWinner(winner);
  };

  const winnersNames = storedWinners.map((item) => item.name);

  //console.log("winnersNames", winnersNames);

  const selectWinners = ["1", "2", "3"];

  useEffect(() => {
    let gifts = [];

    if (selectedWinner === "1") {
      gifts = [
        {
          name: "Выиграй поездку в Дубай",
          img: require("../../img/gift/tket.png"),
          //win: "1",
        },
      ];
    } else if (selectedWinner === "2") {
      gifts = Array(10).fill({
        name: "Денежный приз",
        img: require("../../img/gift/cash.png"),
        // win: "2",
      });
    } else if (selectedWinner === "3") {
      gifts = Array(50).fill({
        name: "1 коробка FOC Chocodate",
        img: require("../../img/gift/box.png"),
        //win: "3",
      });
    }

    setWinnerGifts(gifts);
  }, [selectedWinner]);

  const pickWinners = () => {
    let filteredContestants = contestants
      .trim()
      .split("\n")
      .filter((c) => c && !winnersNames.includes(c.trim())); // Filter out winners

    // Update the contestants state with filtered list (for UI and future picks)
    setContestants(filteredContestants.join("\n"));

    // If no contestants remain after filtering, alert and return
    if (filteredContestants.length < winnerCount) {
      alert("There are more winners than contestants. Everyone is a winner!");
      return;
    }

    setIsPicking(true);
    setWinners([]);
    setCountdown(10);

    const nameInterval = setInterval(() => {
      setDisplayNames(
        Array.from(
          { length: winnerCount },
          () =>
            filteredContestants[
              Math.floor(Math.random() * filteredContestants.length)
            ]
        )
      );
    }, 200);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownInterval);
          clearInterval(nameInterval);
          startPickingWinners(filteredContestants); // Use filtered list for final selection
        }
        return prevCountdown - 1;
      });
    }, 800);
  };
  const startPickingWinners = (contestantsArray) => {
    let selectedWinners = [];
    let selectedGifts = [];
    let availableContestants = [...contestantsArray];

    for (let i = 0; i < winnerCount; i++) {
      let randomIndex = Math.floor(Math.random() * availableContestants.length);
      selectedWinners.push(availableContestants[randomIndex]);
      availableContestants.splice(randomIndex, 1);

      selectedGifts.push(winnerGifts[i]);
    }

    const winnersWithDetails = selectedWinners.map((winner, index) => ({
      name: winner,
      gift: selectedGifts[index]?.name,
      place: selectedGifts[index]?.win,
      img: selectedGifts[index]?.img,
    }));

    setWinners(winnersWithDetails);
    setIsPicking(false);
  };

  useEffect(() => {
    if (winners.length > 0) {
      setStoredWinners(winners); // Store winners separately
      localStorage.setItem("winners", JSON.stringify(winners));
    }
  }, [winners]);

  // useEffect(() => {
  //   const savedWinners = JSON.parse(localStorage.getItem("winners")) || [];
  //   setWinners(savedWinners);
  // }, []);

  // const context = () => {
  //   const number = Date.now();
  //   const bID = "CON-" + number;
  //   return bID;
  // };
  // const contextNo = context();

  // useEffect(() => {
  //   if (!winners || winners.length === 0) {
  //     return;
  //   }
  //   const sendWinner = async () => {
  //     const data = {
  //       winners,
  //       contextNo,
  //     };
  //     try {
  //       await axios.post("/al-kabeer-contest-winner", data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   sendWinner();
  // }, [winners]);

  const saveWinnersToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      winners.map((winner) => ({
        Name: winner.name,
        Gift: winner.gift,
        Place: winner.place,
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Winners");

    // Generate the Excel file and trigger download
    XLSX.writeFile(workbook, "winners.xlsx");
  };

  return (
    <div
      className="context_wrp"
      style={{
        backgroundImage: `url(${xlBk})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    >
      <div className="winner-picker">
        <div>
          {countdown !== null && countdown > 0 && (
            <div className="countdown">
              <h1>{countdown}</h1>
              <h2>Выбираем победителя...</h2>
              <h2>Picking a Winner...</h2>
              <ul className="random-names">
                {displayNames.map((name, index) => (
                  <li key={index} className="changing-name">
                    {name}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {winners.length > 0 && (
            <div className="the-winners">
              <div className="img_wrp">
                <img src={require("../../img/party.gif")} alt="celebration" />
              </div>
              <div className="winner-title">
                <h2>
                  {winnerCount > 1 ? "А победители..." : " А победитель..."}
                </h2>
                <h1>
                  {winnerCount > 1
                    ? "And the winners are"
                    : "And the winner is"}
                </h1>
              </div>
              <ul>
                {winners.map((winner, index) => (
                  <li key={index} className="winner-name">
                    <div className="w_hed">
                      <h3>{winner.place}</h3>{" "}
                      {/* <img
                        src={require("../../img/win.png")}
                        alt="celebration"
                      /> */}
                    </div>
                    {/* This should be a string now */}
                    <h2 style={{ color: "#0070f3", fontSize: "25px" }}>
                      {winner.name}
                    </h2>{" "}
                    {/* Winner's name */}
                    <img
                      src={winner.img}
                      alt={winner.gift}
                      className="gift-img"
                    />
                    <span>{winner.gift}</span> {/* Winner's gift name */}
                  </li>
                ))}
              </ul>
              <div>
                <button className="btn" onClick={saveWinnersToExcel}>
                  Save Winners as Excel
                </button>
              </div>
            </div>
          )}
        </div>

        {!countdown && (
          <div className="contestant-selection">
            {winners.length === 0 && (
              <>
                <h2
                  style={{
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  Кто счастливчик?
                </h2>
                <h2
                  style={{
                    color: "#FFA500",
                  }}
                >
                  Who’s the Lucky Winner?
                </h2>
                {selectedWinner === "1" && (
                  <h2 style={{ color: " #3b82f6" }}>
                    {selectedWinner}st Place {winnerCount} Winner
                  </h2>
                )}
                {selectedWinner === "2" && (
                  <h2 style={{ color: " #3b82f6" }}>
                    {selectedWinner}nd Place {winnerCount} Winners
                  </h2>
                )}
                {selectedWinner === "3" && (
                  <h2 style={{ color: " #3b82f6" }}>
                    {selectedWinner}rd Place {winnerCount} Winners
                  </h2>
                )}

                <textarea
                  placeholder="List of Contestants"
                  className="contestants"
                  value={contestants}
                  onChange={(e) => setContestants(e.target.value)}
                ></textarea>

                <div className="nub_w">
                  <small>Choose the number of winners</small>
                  <input
                    className="winner-count"
                    type="number"
                    value={winnerCount}
                    min="1"
                    onChange={(e) => setWinnerCount(Number(e.target.value))}
                  />
                </div>
                <div className="winnerWrp">
                  {selectWinners.map((winner) => (
                    <span
                      key={winner}
                      className={selectedWinner === winner ? "selected" : ""}
                      onClick={() => handleSelect(winner)}
                    >
                      {winner}
                    </span>
                  ))}
                </div>
                <button className="btn" onClick={pickWinners}>
                  Pick Winners!
                </button>
              </>
            )}
            <span
              onClick={() => {
                // When clicking Pick Again, we reset the list again based on the filtered contestants
                let filteredContestants = contestants
                  .trim()
                  .split("\n")
                  .filter((c) => c && !winnersNames.includes(c.trim())); // Exclude winners

                // Update the textarea with the filtered list
                setContestants(filteredContestants.join("\n"));

                // Clear previous winners for re-pick
                setWinners([]);
              }}
            >
              <small style={{ fontSize: "10px" }}>Pick Again</small>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default WinnerPicker;
